<template>
  <v-card>
    <v-snackbar
      content-class="centered"
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-card-title class="grid-close">
      <span class="headline">{{ $tc("dialog.uploadFiles") }}</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <v-col class="align-end mt-10" cols="12" sm="12" md="12">
        <v-btn
          :loading="loading"
          v-if="!uploaded"
          @click="filePicker"
          color="buttons"
        >
          <v-icon class="mr-3">fas fa-plus</v-icon>
          {{ $tc("media.btn.add") }}</v-btn
        >
      </v-col>
    </v-card-subtitle>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-file-input
            accept="image/*,video/*"
            show-size
            style="display: none"
            id="file"
            counter
            :disabled="loading"
            @change="selectedFiles"
            label="Seleccionar archivo"
            rounded
            multiple
            filled
          ></v-file-input>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :headers="headers"
              :items="files"
              :items-per-page="50"
              :no-data-text="$tc('table.no_data_text')"
              :no-results-text="$tc('table.no_data_text')"
              :loading="loading"
              class="pl-5 pr-5"
              :loading-text="$tc('table.loading')"
              :sort-by="['size']"
              :sort-asc="true"
              item-key=".key"
              fixed-header
              :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.preview`]="{ item }">
                <img
                  :id="item.id"
                  @click="showFile(item)"
                  style="
                    width: 130px;
                    cursor: pointer;
                    height: 80px;
                    object-fit: cover;
                  "
                  v-if="item.type == 'image'"
                  class="ma-3 ml-0"
                  :src="item.preview"
                />

                <video
                  @click="showFile(item)"
                  :id="item.id"
                  style="width: 130px; cursor: pointer; height: 80px"
                  v-if="item.type == 'video'"
                  :src="item.preview"
                ></video>
              </template>
              <template v-slot:[`item.description`]="{ item }">
                <v-text-field
                  rounded
                  filled
                  hide-details
                  :placeholder="$tc('headers.description')"
                  v-model="item.description"
                />
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <v-text-field
                  rounded
                  filled
                  hide-details
                  :placeholder="$tc('headers.name')"
                  v-model="item.name"
                />
              </template>
              <template v-slot:[`item.size`]="{ item }">{{
                item.size | filterSize
              }}</template>
              <template v-slot:[`item.progress`]="{ item }">
                <v-progress-linear
                  :value="item.progress"
                  :color="
                    item.progress < 100
                      ? 'progress-bar'
                      : 'progress-bar-completed'
                  "
                  style="border-radius: 5px"
                  height="30"
                >
                  <strong
                    :class="item.progress < 100 ? 'black--text' : 'white--text'"
                    >{{ Math.ceil(item.progress) }}%</strong
                  >
                </v-progress-linear>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-1"
                      fab
                      v-on="on"
                      @click="remove(item)"
                      dark
                      x-small
                      v-if="item.progress == 0"
                      :elevation="0"
                      color="red darken-4"
                    >
                      <v-icon dark>fa-trash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $tc("table.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="align-end mb-5">
      <v-btn
        width="10%"
        class="mt-5"
        v-if="!uploaded"
        :disabled="!files.length || uploaded"
        :loading="loading"
        @click="save()"
        color="buttons"
        >{{ $tc("button.upload") }}</v-btn
      >
    </v-card-actions>
    <v-dialog
      hide-overlay
      fullscreen
      v-if="viewFileDialog"
      v-model="viewFileDialog"
    >
      <view-file
        :url="selectedFile.preview"
        :type="selectedFile.type"
        @cancel="viewFileDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { db, fb } from "@/firebase";
import { mapState } from "vuex";
import VideoToThumb from "video-thumb-generator";
import viewFile from "@/components/view-file";

import { customAlphabet } from "nanoid";
const nanoid = customAlphabet("123456789", 10);

export default {
  name: "add-item",
  props: ["selectedItem", "itemName", "mediaType"],
  components: {
    viewFile,
  },
  data() {
    return {
      // item: {
      //   name: "",
      // },
      uploaded: false,
      snackbarText: "",
      snackbar: false,
      // defaultImage: require("@/assets/wink-placeholder.png"),
      loading: false,
      files: [],
      // progress: 0,
      // fileSrc: "",
      viewFileDialog: false,
      selectedFile: null,
      headers: [
        { text: "", value: "preview", width: "150px" },
        { text: this.$tc("headers.name"), value: "name" },
        { text: this.$tc("headers.description"), value: "description" },
        { text: this.$tc("headers.size"), value: "size" },
        { text: "", value: "progress", width: "200px" },
        { text: "", value: "actions", align: "end" },
      ],
    };
  },
  computed: {
    ...mapState(["user", "userDoc", "groupId"]),
  },
  filters: {
    filterSize(size) {
      let mb = size / 1048576;
      let gb = mb > 1024 ? mb / 1025 : 0;

      return gb ? `${gb.toFixed(2)} GB` : `${mb.toFixed(2)} MB`;
    },
  },

  methods: {
    showFile(item) {
      this.selectedFile = item;
      this.viewFileDialog = true;
    },
    remove(e) {
      this.loading = true;
      this.files = this.files.filter((i) => i.id != e.id);
      this.loading = false;
    },
    // update() {
    //   if (this.item.name) {
    //     this.loading = true;

    //     db.collection("files")
    //       .doc(this.item[".key"])
    //       .update({
    //         name: this.item.name,
    //         description: this.item.description ? this.item.description : "",
    //       })
    //       .then((response) => {
    //         this.loading = false;
    //         this.$emit("success", "updated");
    //       })
    //       .catch((err) => {
    //         this.loading = false;
    //         this.snackbarText = this.$tc("messages.unknown");
    //         this.snackbar = true;
    //       });
    //   } else {
    //     this.snackbarText = this.$tc("add_item.empty_fields");
    //     this.snackbar = true;
    //   }
    // },
    filePicker() {
      document.getElementById("file").click();
    },
    validate() {
      return new Promise((resolve, reject) => {
        for (let i = 0; i < this.files.length; i++) {
          const file = this.files[i];
          if (!file.name) {
            this.snackbarText = this.$tc("add_item.empty_fields");
            this.snackbar = true;

            return resolve(false);
          }
        }

        return resolve(true);
      });
    },
    async save() {
      let validation = await this.validate();

      if (validation) {
        this.loading = true;
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          file.dataName = `${file.id}_${nanoid(15)}.${file.extension}`;

          let data = {
            extension: file.extension,
            dataName: file.dataName,
            createdAt: new Date(),
            createdBy: this.user.uid,
            size: file.size,
            type: file.type,
            groupId: this.groupId,
            description: file.description,
            name: file.name,
          };

          let reference = db.collection("files").doc();
          let url = await this.uploadFile(reference.id, file, i);

          let screenshot = "";

          if (file.type == "image") {
            screenshot = await this.uploadScreenshot(reference.id, file.id);
          } else {
            screenshot = await this.uploadVideoScreenshot(
              reference.id,
              file.file
            );
          }

          data.screenshot = screenshot;
          data.url = url;

          reference
            .set(data)
            .then((response) => {
              // console.log(response);
            })
            .catch((err) => {
              this.loading = false;
              this.snackbarText = this.$tc("messages.unknown");
              this.snackbar = true;
            });
        }
        this.snackbarText = "Archivos guardados exitosamente.";
        this.snackbar = true;

        this.uploaded = true;
        this.loading = false;
      }
    },
    uploadFile(fileId, file, index) {
      return new Promise((resolve, reject) => {
        var uploadTask = fb
          .app()
          .storage("gs://beanage-files")
          .ref()
          .child(`${this.groupId}/${fileId}/${file.dataName}`)
          .put(file.file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.files[index].progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL) => {
                resolve(downloadURL);
              })
              .catch((err) => {});
          }
        );
      });
    },

    uploadScreenshot(fileId, id) {
      return new Promise((resolve, reject) => {
        let image = document.getElementById(id);
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        // console.log(image.height);
        ctx.drawImage(image, 0, 0, canvas.width, 120);
        var img_data = canvas.toDataURL("image/jpg");

        var uploadTask = fb
          .app()
          .storage("gs://beanage-files")
          .ref()
          .child(`${this.groupId}/${fileId}/file-screenshot.jpg`)
          .putString(img_data, "data_url");
        uploadTask.on(
          "state_changed",
          (snapshot) => {},
          function (error) {
            this.loading = false;
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    },

    uploadVideoScreenshot(fileId, file) {
      return new Promise((resolve, reject) => {
        const videoToThumb = new VideoToThumb(file);
        videoToThumb
          .load()
          .positions([1])
          .type("base64")
          .error(function (err) {})
          .done((img) => {
            let url = img[0];
            var uploadTask = fb
              .app()
              .storage("gs://beanage-files")
              .ref()
              .child(`${this.groupId}/${fileId}/file-screenshot.jpg`)
              .putString(url, "data_url");
            uploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                this.loading = false;
                this.snackbarText = this.$tc("messages.unknown");
                this.snackbar = true;
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  // console.log(downloadURL);
                  resolve(downloadURL);
                });
              }
            );
          });
      });
    },

    selectedFiles(e) {
      this.loading = true;

      for (let index = 0; index < e.length; index++) {
        const file = e[index];

        let name = "";
        let fileNameArray = file && file.name ? file.name.split(".") : "";
        let extension = "";

        if (fileNameArray && fileNameArray.length) {
          extension = fileNameArray.pop();
          name = fileNameArray.join();
        }
        let splitType = file.type.split("/");
        let type = splitType ? splitType[0] : "";

        var reader = new FileReader();

        reader.onload = (e) => {
          this.files.push({
            file,
            name,
            description: "",
            size: file.size,
            type,
            progress: 0,
            preview: e.target.result,
            id: nanoid(30),
            extension,
          });
        };
        reader.readAsDataURL(file);
      }
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
  },
  // mounted() {
  //   if (this.selectedItem) {
  //     this.item = Object.assign({}, this.selectedItem);
  //     this.loading = false;
  //   }
  // },
};
</script>

<style scoped>
.preview {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.image-container {
  width: 100%;
  margin-top: 5px;
  max-height: 235px;
  position: relative;
}

.add {
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #d4d4d487;
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999;
}

#video {
  width: 100%;
  max-height: 235px;
  min-height: 235px;
  object-fit: contain;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>