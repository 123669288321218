<template>
  <div>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-container fluid>
      <v-row class="header-title-actions">
        <v-col cols="12" sm="6" md="6">
          <h1 class="page-title">{{ $tc("mediaF", 0) }}</h1>
        </v-col>

        <v-col class="align-end" cols="12" sm="6" md="6">
          <v-btn color="buttons" @click="showaddFilesDialog" :loading="loading">
            <v-icon left> fas fa-plus </v-icon>
            {{ $tc("media.add") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <!-- <v-col cols="12" sm="12" md="12">
          <search :loading="loading" :name="$tc('mediaF', 1)" />
        </v-col> -->

        <v-col cols="12" sm="12" md="12">
          <v-tabs v-model="tab" centered>
            <v-tab class="font-weight-bold">{{ $tc("media.tabs.all") }}</v-tab>
            <v-tab class="font-weight-bold">{{
              $tc("media.tabs.videos")
            }}</v-tab>
            <v-tab class="font-weight-bold">{{
              $tc("media.tabs.images")
            }}</v-tab>
          </v-tabs>
        </v-col>

        <v-col class="mt-5" cols="12" sm="12" md="12">
          <files-table
            @menuClick="handleMenuActions($event)"
            :items="files"
            :showView="true"
            :showEdit="true"
            :showDelete="true"
            :loading="loading"
            :headers="getHeaders"
            :appendRow="true"
            sortBy="createdAt"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="addFilesDialog"
      persistent
      scrollable
      v-model="addFilesDialog"
      max-width="1200px"
    >
      <add-files
        :selectedItem="selectedItem"
        @success="videoUploaded"
        type="video"
        itemName="video"
        mediaType="video"
        @cancel="
          addFilesDialog = false;
          selectedItem = null;
        "
      />
    </v-dialog>

    <v-dialog
      v-if="editDialog"
      persistent
      v-model="editDialog"
      max-width="700px"
    >
      <edit-item
        :selectedItem="selectedItem"
        @success="editedSuccess"
        :mediaType="selectedItem.type"
        @cancel="editDialog = false"
        :itemName="
          selectedItem.type == 'image'
            ? $tc('images.title')
            : $tc('videos.title')
        "
      />
    </v-dialog>

    <v-dialog
      hide-overlay
      fullscreen
      v-if="viewVideoDialog"
      v-model="viewVideoDialog"
    >
      <view-file
        :url="selectedItem.url"
        :type="selectedItem.type"
        @cancel="viewVideoDialog = false"
      />
    </v-dialog>

    <v-dialog
      max-width="500px"
      v-if="deleteVideoDialog"
      v-model="deleteVideoDialog"
    >
      <delete-video
        ref="deleteComponent"
        :name="selectedItem.name"
        @success="confirmDelete"
        @cancel="deleteVideoDialog = false"
      />
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import { mapActions, mapState } from "vuex";
import viewFile from "@/components/view-file";
import deleteVideo from "@/components/delete";
import filesTable from "@/components/files-table";
import addFiles from "@/components/add-files";
import editItem from "@/components/add-item";

export default {
  name: "media",
  components: {
    addFiles,
    viewFile,
    deleteVideo,
    filesTable,
    editItem,
  },
  data() {
    return {
      files: [],
      snackbarText: "",
      snackbar: false,
      loading: true,
      addFilesDialog: false,
      selectedItem: null,
      viewVideoDialog: false,
      deleteVideoDialog: false,
      editDialog: false,
      tab: 0,
      headers: [
        {
          value: "image",
          width: "150px",
          tabs: [0, 1, 2],
        },
        {
          text: this.$tc("headers.name"),
          value: "name",
          width: "150px",
          tabs: [0, 1, 2],
        },

        {
          text: this.$tc("headers.size"),
          value: "size",
          width: "150px",
          tabs: [0, 1, 2],
        },
        // {
        //   text: this.$tc("headers.duration"),
        //   value: "duration",
        //   width: "150px",
        //   tabs: [0, 1],
        // },
        {
          text: this.$tc("headers.description"),
          value: "description",
          width: "150px",
          tabs: [0, 1, 2],
        },
        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
          tabs: [0, 1, 2],
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "200px",
          tabs: [0, 1, 2],
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId"]),
    getHeaders() {
      return this.headers.filter((i) => i.tabs.includes(this.tab));
    },
  },

  watch: {
    tab() {
      this.getFiles();
    },
  },

  methods: {
    ...mapActions(["cleanSearch"]),
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    editedSuccess() {
      this.editDialog = false;
      this.snackbarText =
        this.selectedItem.type == "image"
          ? this.$tc("images.updated")
          : this.$tc("videos.updated");
      this.snackbar = true;
      this.selectedItem = null;
    },
    handleMenuActions(payload) {
      switch (payload.action) {
        case "show_image":
          this.showViewDialog(payload.item);
          break;
        case "edit":
          this.editItemF(payload.item);
          break;
        case "delete":
          this.showDeleteDialog(payload.item);
          break;
      }
    },
    showaddFilesDialog() {
      this.selectedItem = null;
      this.addFilesDialog = true;
    },
    showViewDialog(item) {
      this.selectedItem = item;
      this.viewVideoDialog = true;
    },
    editItemF(item) {
      this.selectedItem = item;
      this.editDialog = true;
    },

    showDeleteDialog(item) {
      this.selectedItem = item;
      this.deleteVideoDialog = true;
    },
    async confirmDelete() {
      this.$refs.deleteComponent.loading = true;
      db.collection("files")
        .doc(this.selectedItem[".key"])
        .delete()
        .then(async (response) => {
          await this.deleteFile();
          this.snackbarText = this.$tc("videos.deleted");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
          this.deleteVideoDialog = false;
        })
        .catch((error) => {
          this.snackbarText = this.$tc("messages.unknown");
          this.snackbar = true;
          this.$refs.deleteComponent.loading = false;
        });
    },
    deleteFile() {
      return new Promise(async (resolve, reject) => {
        let storageRef = fb.app().storage("gs://beanage-files").ref();
        var desertRef = storageRef.child(
          `${this.groupId}/${this.selectedItem[".key"]}/${this.selectedItem.dataName}`
        );

        desertRef
          .delete()
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            this.snackbarText = this.$tc("messages.unknown");
            this.snackbar = true;
            this.$refs.deleteComponent.loading = false;
            reject(false);
          });
      });
    },
    videoUploaded() {
      this.addFilesDialog = false;
      this.snackbarText = this.selectedItem
        ? this.$tc("videos.updated")
        : this.$tc("videos.added");
      this.snackbar = true;
      this.selectedItem = null;
    },

    async getFiles() {
      this.loading = true;
      let query = db.collection("files").where("groupId", "==", this.groupId);

      if (this.tab == 1 || this.tab == 2) {
        query = query.where("type", "==", this.tab == 1 ? "video" : "image");
      }

      await this.$binding("files", query);

      this.loading = false;

      //   db.collection("files")
      //     .where("groupId", "==", this.groupId)
      //     .where("type", "==", this.tab == 0 ? "video" : "image")
      //     .onSnapshot((response) => {
      //       this.videos = [];
      //       response.forEach((item) => {
      //         let video = item.data();
      //         video[".key"] = item.id;
      //         this.videos.push(video);
      //       });
      //       this.loading = false;
      //     });
    },
  },
  mounted() {
    this.cleanSearch();

    this.getFiles();
  },
};
</script>

<style>
.v-dialog--fullscreen {
  border-radius: 0 !important;
  background-color: #000000e3;
}
</style>